import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createMemoryHistory } from "history";
import { createRoot } from 'react-dom/client';
import Main from './components/Main';
import About from "./components/About";
import Contact from "./components/Contact/index.js";
import registerServiceWorker from './registerServiceWorker';
import './index.css';

const history = createMemoryHistory();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter location={history.location} navigator={history}>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  </BrowserRouter>
);
registerServiceWorker();
