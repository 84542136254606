import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import times from "lodash/times";
import cx from "classnames";
import { PAGE_SIZE, SITE_COLORS } from "../../lib/constants";
import "./style.css";

const NUM_PAGE_LINKS = 10;

function PageLink(props) {
  return (
    <button
      name={props.name}
      onClick={props.onClick}
      disabled={props.disabled}
      className={cx("pageLink", { "active": props.active })}
    >
      {props.children}
    </button>
  )
}

export default class Results extends Component {
  static propTypes = {
    error: PropTypes.bool,
    site: PropTypes.string,
    results: PropTypes.array,
    totalResults: PropTypes.number,
    handleSearch: PropTypes.func,
    searching: PropTypes.bool,
    fetching: PropTypes.bool,
    activePage: PropTypes.number
  }

  handleSearch = (page) => (event) => {
    if (event) event.preventDefault();
    this.props.handleSearch(page);
  }

  getSite = (url) => {
    if (url.indexOf("gossamer") > -1) {
      return "Gossamer";
    }

    if (url.indexOf("xphilefic") > -1) {
      return "xphilefic";
    }

    if (url.indexOf("xffics") > -1) {
      return "xffics";
    }

    return "other";
  }

  renderPageLink = (pages, isLastPage) => (num) => {
    let page = num;

    if (isLastPage && pages > NUM_PAGE_LINKS) {
      page += (pages - NUM_PAGE_LINKS);
    } else {
      page += Math.floor(this.props.activePage/ NUM_PAGE_LINKS) * NUM_PAGE_LINKS;
    }

    return (
      <PageLink
        key={page}
        name={page}
        onClick={this.handleSearch(page)}
        active={page === this.props.activePage}
      >
        {page + 1}
      </PageLink>
    );
  }

  renderPages = (pages) => {
    if (this.props.totalResults < 1) return null;

    const { activePage } = this.props;
    const isLastPage = activePage > pages - NUM_PAGE_LINKS - 1;
    const numPages = pages < NUM_PAGE_LINKS ? pages : NUM_PAGE_LINKS;

    const showFirst = activePage >= NUM_PAGE_LINKS || (pages > NUM_PAGE_LINKS && pages <= NUM_PAGE_LINKS * 2 && activePage >= pages - NUM_PAGE_LINKS)
    return (
      <div className="pageLinks">
        <PageLink
          key="back"
          name="back"
          onClick={this.handleSearch(activePage - 1)}
          disabled={activePage < 1}
        >
          <span className="leftArrow">{"▶︎"}</span>
        </PageLink>
        {showFirst &&
          <PageLink
           name="0"
           onClick={this.handleSearch(0)}
          >
            1
          </PageLink>}
        {showFirst && <span>...</span>}

        {times(numPages, this.renderPageLink(pages, isLastPage))}

        {!isLastPage && <span>...</span>}
        {!isLastPage &&
          <PageLink
            name={`${pages}`}
            onClick={this.handleSearch(pages - 1)}
          >
          {pages}
          </PageLink>}
        <PageLink
          key="next"
          name="next"
          onClick={this.handleSearch(activePage + 1)}
          disabled={activePage === pages - 1}
        >
          <span className="rightArrow">{"▶︎"}</span>
        </PageLink>
      </div>
    )
  }

  renderResult = (result) => {
    const site = this.getSite(result.url);
    const size = Math.floor(result.size / 1000);
    return (
      <div key={result.id} className="result">
        <div className="resultHeader">
          <a href={result.url} target="_blank" rel="noopener noreferrer">
            <strong>{result.title}</strong>
          </a>
           {" - "}
           <em>{unescape(result.author)}</em>
        </div>
        <div>
          {result.rating || "Not Rated"}{!!size && (`, ${size}k`)}{!!result.category && `, ${result.category}`}
        </div>
        {result.summary && <div className="summary">{result.summary}</div>}
        {result.keywords && <span><span className="label">Keywords: </span>{result.keywords}</span>}
        {result.spoilers && <div><span className="label">Spoilers: </span>{result.spoilers}</div>}
        <div className="foundOn" style={{ color: SITE_COLORS[site]}}>
          found on:{" "}<strong>{site}</strong>
        </div>
        {result.pg_search_highlight &&
          <p
            className="highlight"
            dangerouslySetInnerHTML={{__html: `${result.pg_search_highlight}`}}
          />
        }
      </div>
    );
  }

  renderError() {
    return (
      <div className="resultsWrap">
        <h3 className="resultsError">
          There was an error. Sorry! Try reloading the page.
        </h3>
      </div>
    )
  }

  render() {
    if (this.props.error) return this.renderError();

    const pages = Math.ceil(this.props.totalResults / PAGE_SIZE);
    return (
      <div className="resultsWrap">
        <h3 className="resultsTotal">
          {`${this.props.totalResults} stories found`}
        </h3>
        {this.renderPages(pages)}
        <div className={cx("list", {"searching": this.props.fetching})}>
          {this.props.results.map(this.renderResult)}
        </div>
        {this.renderPages(pages)}
      </div>
    );
  }
}
