import React from "react";
import cx from "classnames";
import "./style.css";

export default function Input({ className, type, ...rest }) {
  const InputComp = type === "textarea" ? "textarea" : "input";
  return (
    <InputComp
      className={cx("input", className)}
      {...rest}
    />
  )
}
