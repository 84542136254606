import React, { Component } from 'react';
import Wrapper from "./Wrapper";

export default class About extends Component {
  render() {
    return (
      <Wrapper>
        <div className="centered">
          <h3>About</h3>
          <p>
            A simple search engine for X-Files fic. To avoid archiving against
            the wishes of any authors, no stories are hosted here directly--
            I{"'"}m just making it easier to find them. Currently only
            stories from Gossamer are completely searchable, but I{"'"}m slowly
            adding fic from other older sites as well.
          </p>
          <p>
            The site is still a work in progress! It may be a bit slow or buggy.
            If something isn{"'"}t working, the best bet is to refresh or
            hard-refresh (SHIFT-CMD-R). If a Gossamer link won{"'"}t load, try
            swapping <strong>fluky</strong> in the URL with <strong>krycek</strong>
            {" "}or <strong>tooms</strong>.
          </p>
          <p>
            Because there are so many stories, I{"'"}ve populated the results
            using a custom parser (that makes its best guess as to what the
            spoilers, keywords, etc. are), rather than enter them manually. So
            some of the information may not be exactly right. But the title, url,
            author, and text always should be. (Let me know if they aren{"'"}t!)
          </p>
          <p>
            Any contributions appreciated, if you have it to spare. Purely to
            maintain the site. I{"'"}ll take the link down if they{"'"}re no
            longer needed.
          </p>
          <p>Enjoy!</p>
          <div className="paypal">
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="JS5TRQSTJ5TFE" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
        </div>
      </Wrapper>
    )
  }
}
