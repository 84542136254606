/* global HTMLElement, window */
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import CheckboxGroup from "../CheckboxGroup"
import Dropdown from "../Dropdown";
import Button from "../Button";
import Input from "../Input";
import {
  RATINGS,
  SEASONS,
  MOVIES,
  SIZES,
  KEYWORDS,
  getStoryTypes
} from "../../lib/constants";
import "./style.css";

const DEFAULT_RATINGS = clone(RATINGS);
const DEFAULT_SEASONS = clone(SEASONS);
const DEFAULT_MOVIES = clone(MOVIES);
const DEFAULT_SIZES = clone(SIZES);
const DEFAULT_KEYWORDS = clone(KEYWORDS);

const settings = {
  "_all": {
    placeholder: "silk boxers",
    label: "anything contains:"
  },
  "text": {
    placeholder: "ford taurus",
    label: "story contains:"
  },
  "title": {
    placeholder: "contact high",
    label: "title contains:"
  },
  "author": {
    placeholder: "punk",
    label: "author name contains:"
  }
}

const sizeRanges = {
  "<5k": { min: "0", max: "5000" },
  "5k - 25k": { min: "5000", max: "25000" },
  "25k - 50k": { min: "25000", max: "50000" },
  "50k - 100k": { min: "50000", max: "100000" },
  "100k - 250k": { min: "100000", max: "250000" },
  ">250k": { min: "250000" }
}

const DEFAULT_FORM_STATE = {
  text: "",
  ratings: [],
  sizes: [],
  keywords: [],
  spoilers: {
    s1: [],
    s2: [],
    s3: [],
    s4: [],
    s5: [],
    s6: [],
    s7: [],
    s8: [],
    s9: [],
    s10: [],
    movies: []
  },
  categories: {
    stories: [],
    xfiles: [],
    adventures: [],
    vignettes: [],
    crossovers: []
  }
};

export default class Search extends Component {
  constructor(props) {
    super();
    this.state = {
      ...clone(DEFAULT_FORM_STATE),
      searchType: "text",
      dropdown: "" // "rating" | "length" | "spoilers" | "category" | "keywords"
    };
  }

  addListener = () => {
    document.body.style.overflow = "auto";
    window.addEventListener("mouseup", this.handleGlobalClick);
  }

  removeListener = () => {
    document.body.style.overflow = "hidden";
    window.removeEventListener("mouseup", this.handleGlobalClick);
  }

  handleGlobalClick = (event) => {
    const dropdowns = document.getElementsByClassName('dropdown') || [];
    if (dropdowns.length) {
      let close = true;
      for (var i = 0; i < dropdowns.length; i++) {
        if (dropdowns[i].contains(event.target)) {
          close = false;
        }
      }

      if (
        this.state.dropdown &&
        event.target.className === `button ${this.state.dropdown}ButtonToggle`
      ) {
        close = false;
      }

      if (close) {
        this.removeListener();
        this.setState({ dropdown: "" });
      }
    }
  }

  getSelectedSpoilers = () => {
    let selected  = [];
    Object.keys(this.state.spoilers).map(key => {
      selected = selected.concat(this.state.spoilers[key]);
    });

    return selected;
  }

  getSelectedCategories = () => {
    let selected  = [];
    Object.keys(this.state.categories).forEach(key => {
      selected = selected.concat(this.state.categories[key]);
    });

    for (var i = 0; i < selected.length; i++) {
      const words = selected[i].split("/")

      for (var j = 0; j < words.length; j++) {
        words[j] = words[j][0];
      }

      selected[i] = words.join("");
    }

    return selected;
  }

  getSizeQuery = () => {
    let ranges = [];
    let min = sizeRanges[this.state.sizes[0]].min;
    let max = 0;

    for (var i = 0; i< this.state.sizes.length; i++) {
      let defaultIndex = SIZES.indexOf(this.state.sizes[i - 1]);
      let currentIndex = SIZES.indexOf(this.state.sizes[i]);

      if (currentIndex === defaultIndex + 1 || i === 0) {
        max = sizeRanges[this.state.sizes[i]].max;
      } else {
        ranges.push([min, max]);
        min = sizeRanges[this.state.sizes[i]].min;
        max = sizeRanges[this.state.sizes[i]].max;
      }
    }

    ranges.push([min, max]);

    return ranges;
  }

  getQuery = () => {
    const query = {};
    const { searchType, text, ratings, keywords, sizes } = this.state;
    const spoilers = this.getSelectedSpoilers();
    const categories = this.getSelectedCategories();

    if (text.length) query[searchType] = text;
    if (spoilers.length) query["spoilers"] = spoilers;
    if (categories.length) query["category"] = categories;
    if (ratings.length) query["rating"] = ratings;
    if (keywords.length) query["keywords"] = keywords;
    if (sizes.length) query["size"] = this.getSizeQuery();

    return query;
  }

  handleInputChange = (event) => {
    const text = event.target.value;

    if (text.length > 100) return event.preventDefault();

    this.setState({ text });
  }

  handleInputTypeChange = (event) => {
    const searchType = event.target.value;
    this.setState({ searchType });
  }

  handleSizesChange = (sizes) => {
    this.setState({ sizes: sizes || [] });
  }

  handleRatingsChange = (ratings) => {
    this.setState({ ratings: ratings || [] });
  }

  handleKeywordsChange = (keywords) => {
    this.setState({ keywords: keywords || [] });
  }

  handleCategoryChange = (category) => (results) => {
    let categories = clone(this.state.categories);
    categories[category] = results;
    this.setState({ categories });
  }

  handleSpoilersChange = (season) => (results) => {
    let spoilers = clone(this.state.spoilers);
    spoilers[season] = results;
    this.setState({ spoilers });
  }

  toggleDropdown = (name) => {
    return (event) => {
      event.preventDefault();
      if (this.state.dropdown === name) {
        this.setState({ dropdown: "" }, () => {
          this.removeListener();
        });
      } else {
        this.setState({ dropdown: name }, () => {
          this.addListener();
        });
      }
    }
  }

  reset = () => {
    this.setState(clone(DEFAULT_FORM_STATE));
  }

  clearFilters = (event) => {
    event.preventDefault();
    const { text, ...rest } = clone(DEFAULT_FORM_STATE);
    this.setState({ ...rest });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const query = this.getQuery();
    this.props.onSubmit(query);
  }

  renderRatings() {
    return (
      <Dropdown
        label="rating"
        onToggle={this.toggleDropdown("rating")}
        open={this.state.dropdown === "rating"}
      >
        <CheckboxGroup
          options={DEFAULT_RATINGS}
          selected={this.state.ratings}
          onChange={this.handleRatingsChange}
        />
      </Dropdown>
    )
  }

  renderLength() {
    return (
      <Dropdown
        label="length"
        onToggle={this.toggleDropdown("length")}
        open={this.state.dropdown === "length"}
      >
        <div className="dropdownInner">
          <CheckboxGroup
            options={DEFAULT_SIZES}
            selected={this.state.sizes}
            onChange={this.handleSizesChange}
          />
        </div>
      </Dropdown>
    );
  }

  renderSpoilers() {
    return (
      <Dropdown
        label="spoilers"
        className="spoilersDropdown"
        onToggle={this.toggleDropdown("spoilers")}
        open={this.state.dropdown === "spoilers"}
      >
        {DEFAULT_SEASONS.map((season, index) => (
          <CheckboxGroup
            all
            key={index}
            label={season[0]}
            options={season}
            selected={this.state.spoilers[`s${index + 1}`]}
            onChange={this.handleSpoilersChange(`s${index + 1}`)}
          />
        ))}
        <CheckboxGroup
          all
          label="Movies"
          options={DEFAULT_MOVIES}
          selected={this.state.spoilers.movies}
          onChange={this.handleSpoilersChange('movies')}
        />
      </Dropdown>
    );
  }

  renderCategories() {
    return (
      <Dropdown
        label="category"
        className="categoriesDropdown"
        onToggle={this.toggleDropdown("category")}
        open={this.state.dropdown === "category"}
      >
        <em className="note">
          nb: these categories mostly only apply to gossamer results
        </em>
        <CheckboxGroup
          title="X-Files"
          options={getStoryTypes("X-File")}
          selected={this.state.categories.xfiles}
          onChange={this.handleCategoryChange('xfiles')}
        />
        <CheckboxGroup
          title="Stories"
          options={getStoryTypes("Story")}
          selected={this.state.categories.stories}
          onChange={this.handleCategoryChange('stories')}
        />
        <CheckboxGroup
          title="Adventures"
          options={getStoryTypes("Adventure")}
          selected={this.state.categories.adventures}
          onChange={this.handleCategoryChange('adventures')}
        />
        <CheckboxGroup
          title="Vignettes"
          options={getStoryTypes("Vignette")}
          selected={this.state.categories.vignettes}
          onChange={this.handleCategoryChange('vignettes')}
        />
        <CheckboxGroup
          title="Crossovers"
          options={getStoryTypes("Crossover")}
          selected={this.state.categories.crossovers}
          onChange={this.handleCategoryChange('crossovers')}
        />
      </Dropdown>
    );
  }

  renderKeywords() {
    return (
      <Dropdown
        label="keywords"
        className="keywordsDropdown"
        onToggle={this.toggleDropdown("keywords")}
        open={this.state.dropdown === "keywords"}
      >
        <CheckboxGroup
          options={DEFAULT_KEYWORDS}
          selected={this.state.keywords}
          onChange={this.handleKeywordsChange}
        />
      </Dropdown>
    );
  }

  renderFilters() {
    const selectedSpoilers = this.getSelectedSpoilers();
    const selectedCategories = this.getSelectedCategories();
    const { ratings, sizes, keywords } = this.state;

    return (
      <div className="querySummary">
        <div>
          <Button
            onClick={this.clearFilters}
            className="clearFiltersButton"
          >
            clear filters x
          </Button>
        </div>
        {!!ratings.length &&
          <div>
            <label>rated:</label>
            {ratings.join(", ")}
          </div>
        }
        {!!sizes.length &&
          <div>
            <label>length:</label>
            {sizes.join(", ")}
          </div>
        }
        {!!selectedSpoilers.length &&
          <div>
            <label>spoilers:</label>
            { selectedSpoilers.length < 5 ?
              selectedSpoilers.join(", ")
              :
              (` ${selectedSpoilers.slice(0, 4).join(", ")} and ${selectedSpoilers.length - 4} other${selectedSpoilers.length > 5 ? "s" : ""}`)
            }
          </div>
        }
        {!!selectedCategories.length &&
          <div>
            <label>categories:</label>
            { selectedCategories.length < 8 ?
              ` ${selectedCategories.join(", ")}`
              :
              ` ${selectedCategories.slice(0, 7).join(", ")} and ${selectedCategories.length - 7} other${selectedCategories.length > 8 ? "s" : ""}`
            }
          </div>
        }
        {!!keywords.length &&
          <div>
            <label>keywords:</label>
            {keywords.length < 5 ?
              keywords.join(", ")
              :
              ` ${keywords.slice(0, 4).join(", ")} and ${keywords.length - 4} other${keywords.length > 5 ? "s" : ""}`
            }
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      searchType,
      text,
      ratings,
      sizes,
      spoilers,
      categories,
      keywords
    } = this.state;

    const { searching, fetching } = this.props;

    const currentFormState = {
      text, ratings, sizes, spoilers, categories, keywords
    }

    const isDirty = !isEqual(currentFormState, DEFAULT_FORM_STATE);
    const hasFilters = !isEqual(
      { ratings, sizes, spoilers, categories, keywords },
      {
        ratings: DEFAULT_FORM_STATE.ratings,
        sizes: DEFAULT_FORM_STATE.sizes,
        spoilers: DEFAULT_FORM_STATE.spoilers,
        categories: DEFAULT_FORM_STATE.categories,
        keywords: DEFAULT_FORM_STATE.keywords
      }
    );

    return (
      <div className="form">
        <form onSubmit={this.handleSubmit}>
          <div className="basicSearch">
            <span>
              <strong>search</strong>
              <select defaultValue="text" onChange={this.handleInputTypeChange}>
                <option value="_all">anything</option>
                <option value="text">stories</option>
                <option value="title">titles</option>
                <option value="author">authors</option>
              </select>
              <strong>for:</strong>
              <Input
                placeholder={settings[searchType].placeholder}
                name="textinput"
                value={this.state.text}
                maxLength={100}
                onChange={this.handleInputChange}
              />
            </span>
            <Button
              green
              type="submit"
              className="submitButton"
              disabled={searching || !isDirty}
            >
              {(searching && !fetching) ? "searching..." : "search"}
            </Button>
          </div>
          <div className="basicSearch filterBy">
            <strong>by:</strong>
            { this.renderRatings() }
            { this.renderLength() }
            { this.renderSpoilers() }
            { this.renderCategories() }
            { this.renderKeywords() }
          </div>
        { hasFilters && this.renderFilters() }
        {/*<div>
          <strong>keywords</strong>
          <em>This will search by any of gossamer{"'"}s keywords. You can also enter other pairings ("Flukeman/Pendrell") or common categories ("Profiling") and Liminal will do its best to find something that fits.</em>
          <input type="text" name="keywords" placeholder="" />
        </div> */}

        </form>
      </div>
    )
  }
}
