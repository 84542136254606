export const productionUrl = "https://peaceful-lake-56243.herokuapp.com";
export const devUrl = "http://localhost:3000";

export const SEASONS = [
  [
    "Season 1",
    "Pilot",
    "Deep Throat",
    "Squeeze",
    "Conduit",
    "The Jersey Devil",
    "Shadows",
    "Ghost in the Machine",
    "Ice",
    "Space",
    "Fallen Angel",
    "Eve",
    "Fire",
    "Beyond the Sea",
    "Gender Bender",
    "Lazarus",
    "Young at Heart",
    "E.B.E.",
    "Miracle Man",
    "Shapes",
    "Darkness Falls",
    "Tooms",
    "Born Again",
    "Roland",
    "The Erlenmeyer Flask"
  ],
  [
    "Season 2",
    "Little Green Men",
    "The Host",
    "Blood",
    "Sleepless",
    "Duane Barry",
    "Ascension",
    "3",
    "One Breath",
    "Firewalker",
    "Red Museum",
    "Excelsis Dei",
    "Aubrey",
    "Irresistable",
    "Die Hand Die Verletzt",
    "Fresh Bones",
    "Colony",
    "End Game",
    "Fearful Symmetry",
    "Dod Kalm",
    "Humbug",
    "The Calusari",
    "F. Emasculata",
    "Soft Light",
    "Our Town",
    "Anasazi"
  ],
  [
    "Season 3",
    "The Blessing Way",
    "Paper Clip",
    "D.P.O.",
    "Clyde Bruckman's Final Repose",
    "The List",
    "2Shy",
    "The Walk",
    "Oubliette",
    "Nisei",
    "731",
    "Revelations",
    "War of the Coprophages",
    "Syzygy",
    "Grotesque",
    "Piper Maru",
    "Apocrypha",
    "Pusher",
    "Teso Dos Bichos",
    "Hell Money",
    "Jose Chung's From Outer Space",
    "Avatar",
    "Quagmire",
    "Wetwired",
    "Talitha Cumi"
  ],
  [
    "Season 4",
    "Herrenvolk",
    "Home",
    "Teliko",
    "Unruhe",
    "The Field Where I Died",
    "Sanguinarium",
    "Musings of the Cigarette Smoking Man",
    "Tunguska",
    "Terma",
    "Paper Hearts",
    "El Mundo Gira",
    "Leonard Betts",
    "Never Again",
    "Memento Mori",
    "Kaddish",
    "Unrequited",
    "Tempus Fugit",
    "Max",
    "Synchrony",
    "Small Potatoes",
    "Zero Sum",
    "Elegy",
    "Demons",
    "Gethsemane",
    "Redux"
  ],
  [
    "Season 5",
    "Redux II",
    "Unusual Suspects",
    "Detour",
    "The Post-Modern Prometheus",
    "Christmas Carol",
    "Emily",
    "Kitsunegari",
    "Schizogeny",
    "Chinga",
    "Kill Switch",
    "Bad Blood",
    "Patient X",
    "The Red and the Black",
    "Travelers",
    "Mind's Eye",
    "All Souls",
    "The Pine Bluff Variant",
    "Folie a Deux",
    "The End"
  ],
  [
    "Season 6",
    "The Beginning",
    "Drive",
    "Triangle",
    "Dreamland",
    "Dreamland II",
    "How the Ghosts Stole Christmas",
    "Terms of Endearment",
    "The Rain King",
    "S.R. 819",
    "Tithonus",
    "Two Fathers",
    "One Son",
    "Agua Mala",
    "Monday",
    "Arcadia",
    "Alpha",
    "Trevor",
    "Milagro",
    "The Unnatural",
    "Three of a Kind",
    "Field Trip",
    "Biogenesis",
    "The Sixth Extinction"
  ],
  [
    "Season 7",
    "The Sixth Extinction II: Amor Fati",
    "Hungry",
    "Millennium",
    "Rush",
    "The Goldberg Variation",
    "Orison",
    "The Amazing Maleeni",
    "Signs and Wonders",
    "Sein und Zeit",
    "Closure",
    "X-Cops",
    "First Person Shooter",
    "Theef",
    "En Ami",
    "Chimera",
    "all things",
    "Brand X",
    "Hollywood A.D.",
    "Fight Club",
    "Je Souhaite",
    "Requiem"
  ],
  [
    "Season 8",
    "Within",
    "Without",
    "Patience",
    "Roadrunners",
    "Redrum",
    "Via Negativa",
    "Surekill",
    "Salvage",
    "Badlaa",
    "The Gift",
    "Medusa",
    "Per Manum",
    "This Is Not Happening",
    "Deadalive",
    "Three Words",
    "Empedocles",
    "Vienen",
    "Alone",
    "Essence",
    "Existence"
  ],
  [
    "Season 9",
    "Nothing Important Happened Today",
    "Nothing Important Happened Today II",
    "Daemonicus",
    "4-D",
    "Lord of the Flies",
    "Trust No 1",
    "John Doe",
    "Hellbound",
    "Provenance",
    "Providence",
    "Audrey Pauley",
    "Underneath",
    "Improbable",
    "Scary Monsters",
    "Jump the Shark",
    "William",
    "Release",
    "Sunshine Days",
    "The Truth"
  ],
  [
    "Season 10",
    "My Struggle",
    "Founder's Mutation",
    "Mulder and Scully Meet the Were-Monster",
    "Home Again",
    "Babylon",
    "My Struggle II"
  ]
];

export const MOVIES = [
  "Fight the Future",
  "I Want to Believe"
];

export const WEBSITES = [
  "Gossamer",
  "Ephemeral",
  "A03",
  "LJ",
  "Tumblr",
  "Fugues",
  "xphilefic",
  "xffics"
];

export const RATINGS = [
  "G",
  "PG",
  "PG-13",
  "R",
  "NC-17",
  "Not Rated"
];

export const SIZES = [
  "<5k",
  "5k - 25k",
  "25k - 50k",
  "50k - 100k",
  "100k - 250k",
  ">250k"
];

export const KEYWORDS = [
  "Mulder/Scully",
  "MSR",
  "M/S",
  "Mulder/Scully romance",
  "Mulder/Scully UST",
  "Mulder/Scully friendship",
  "Mulder/Scully sex",
  "Mulder/Scully married",
  "Mulder/other",
  "Scully/other",
  "Scully/Skinner",
  "Sc/Sk",
  "Scully POV",
  "Mulder POV",
  "Mulder/Skinner",
  "Mulder/Krycek",
  "M/K",
  "Scully/Krycek",
  "Mulder/Fowley",
  "Krycek/other",
  "Skinner/Maggie Scully",
  "Mulder/Scully/Skinner",
  "Mulder/Scully/Skinner friendship",
  "Mulder/Scully/Krycek",
  "Doggett/Reyes",
  "Scully/Doggett",
  "Scully/Reyes",
  "William",
  "3rd Person POV",
  "Fluff",
  "Angst",
  "Drunkfic",
  "Kidfic",
  "MOTW",
  "Colonization",
  "Slash",
  "Smut",
  "UST",
  "Post-episode",
  "Crossover",
  "Profiling",
  "Casefile",
  "Alternate Universe",
  "Missing scene",
  "Endangered",
  "Poetry",
  "Pre-XF",
  "Character death",
  "Rape"
]

export const SEARCH_SIZE = 60;
export const PAGE_SIZE = 20;

export const SITE_COLORS = {
  "Gossamer": "#598BDB",
  "xphilefic": "#e19508",
  "xffics": "#2e8084",
  "other": "#733196"
}

export const categoryInitials = [
  "S", "SH", "SR", "SHA", "SRA", "SHR", "SHRA",
  "A", "AH", "AR", "AHA", "ARA", "AHR", "AHRA",
  "X", "XH", "XR", "XHA", "XRA", "XHR", "XHRA",
  "V", "VH", "VR", "VHA", "VRA", "VHR", "VHRA",
  "C", "CH", "CR", "CHA", "CRA", "CHR", "CHRA"
]

export function getStoryTypes(type) {
  return [
    type,
    `${type}/Humor`,
    `${type}/Angst`,
    `${type}/Romance`,
    `${type}/Humor/Angst`,
    `${type}/Romance/Angst`,
    `${type}/Humor/Romance`,
    `${type}/Humor/Romance/Angst`
  ];
}
