import React, { Component } from 'react';
import Wrapper from "../Wrapper";
import Button from "../Button";
import Input from "../Input";
import { sendMessage } from "../../lib/api";
import "./style.css";

export default class Contact extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      message: "",
      error: "",
      submitting: false,
      success: false
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { message, email } = this.state;

    if (!message) return this.setState({ error: "Message can't be blank" });

    this.setState({ submitting: true });

    sendMessage({ message, email: email || "anonymous" }, res => {
      if (res === "error") {
        return this.setState({
          error: "There was an error. Sorry! Try again?",
          submitting: false
        });
      }

      this.setState({
        email: "",
        message: "",
        error: "",
        submitting: false,
        success: true
      });
    });
  }

  handleChange = (event) => {
    const target = event.target;
    if (
      (target.name === "email" && target.value.length > 75)
      || (target.name === "message" && target.value.length > 7500)
    ) {
      return event.preventDefault();
    }

    this.setState({
      [target.name]: target.value,
      error: "",
      success: false
    });
  }

  render() {
    const { error, success, message, submitting } = this.state;
    return (
      <Wrapper>
        <div className="centered">
          <h3>Contact</h3>
          <p>
            Compliments? Complaints? Found a bug? Send me a message!
          </p>
          <form onSubmit={this.handleSubmit} className="contactForm">
            <Input
              type="text"
              name="email"
              placeholder="email (totally optional)"
              disabled={submitting}
              value={this.state.email}
              onChange={this.handleChange}
            />
            <Input
              type="textarea"
              name="message"
              rows="5"
              disabled={submitting}
              value={this.state.message}
              onChange={this.handleChange}
            />
            <div className="footer">
              {error && <span className="error">{error}</span>}
              {success && <span className="success">Sent!</span>}
              <Button
                green
                type="submit"
                disabled={!message.length || submitting}
              >
                {submitting? "Sending..." : "Send"}
              </Button>
            </div>
          </form>
        </div>
      </Wrapper>
    )
  }
}
