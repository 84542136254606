import React from "react";
import cx from "classnames";
import "./style.css";

export default function Button({ green, className, ...rest }) {
  return (
    <button
      className={cx("button", className, {"greenButton": green })}
      {...rest}
    />
  )
}
