/* global window */
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export default class Wrapper extends React.Component {
  componentWillMount() {
    const path = window.location.href;
    if (path.indexOf("localhost") < 0 && path.slice(0, 5) !== "https") {
      window.location.assign("https" + path.slice(4, path.length));
    }
  }

  render() {
    return (
      <div className="app">
        <div className="body">
          <div className="headerWrap">
            <h1 onClick={this.props.onHeaderClick}>
              <Link to="/" className="header">Liminal.</Link>
            </h1>
            <div className="links">
              <Link to="/about">about</Link>
              <Link to="/contact">contact</Link>
            </div>
          </div>
          { this.props.children }
        </div>
      </div>
    );
  }
}
