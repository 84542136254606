// import { browserHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
let browserHistory = createBrowserHistory();

export const currentLocation = () => {
  return browserHistory.location;
}

export function replace(location) {
  browserHistory.replace(location);
}

export const queryObjectToSearchString = (query = {}) => {
  const keys = Object.keys(query);
  if (!keys.length) return "";

  let searchString = "?"
  keys.forEach(key => {
    if (searchString.length > 1) searchString += "&";
    searchString += `${key}=${query[key]}`;
  });

  return searchString;
}

export const searchStringToQueryObject = (search = "") => {
  if (!search.length) return {};

  const queries = search.slice(1).split("&");
  let queryObject = {};
  queries.forEach(query => {
    const pair = query.replaceAll("%20", " ").replaceAll("+", " ").split("=");
    queryObject[`${pair[0]}`] = pair[1];
  });

  if (queryObject.size) {
    // console.log(queryObject);
    if (typeof queryObject.size === "string") {
      queryObject.size = [ queryObject.size.split(",") ];
    } else {
      queryObject.size = queryObject.size.map(s => s.split(","));
    }
  };

  return queryObject;
}

// export function replaceWithQuery(search, query) {
//   // console.log(browserHistory);
//   // if (!browserHistory.getCurrentLocation) { return };
//   const location = currentLocation();
//   replace(location.pathname + search);
// }

export function replaceQuery(query) {
  const location = currentLocation();
  const newLocation = {
    ...location,
    search: queryObjectToSearchString(query)
  };
  browserHistory.push(newLocation);
}

export function addQuery(query) {
  const location = currentLocation();
  const queryObject = searchStringToQueryObject(location.search);
  const newQueryObject = { ...queryObject, ...query };
  const newLocation = {
    ...location,
    search: queryObjectToSearchString(newQueryObject)
  };
  browserHistory.push(newLocation);
}

export const removeQuery = (...queryNames) => {
  const location = currentLocation();
  let queryObject = searchStringToQueryObject(location.search);
  queryNames.forEach(q => delete queryObject[q]);
  const newLocation = {
    ...location,
    search: queryObjectToSearchString(queryObject)
  };
  browserHistory.push(newLocation);
}
