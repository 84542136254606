import React, { Component } from 'react';
import Media from "react-media";
import isEqual from "lodash/isEqual";
import clone from "lodash/clone";
import Results from "./Results";
import Search from "./Search";
import Wrapper from "./Wrapper";
import { searchStories } from "../lib/api";
import {
  currentLocation,
  addQuery,
  removeQuery,
  replaceQuery,
  queryObjectToSearchString,
  searchStringToQueryObject
} from "../lib/location";

const DEFAULT_STATE = {
  error: false,
  searching: false,
  results: null,
  activePage: null,
  fetching: false,
};


export default class Main extends Component {
  constructor(props) {
    super();
    this.state = DEFAULT_STATE;
    this.cache = {}
  }

  componentDidMount() {
    const { search } = currentLocation();
    this.handleMount(search);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.searching) return;
    const current = this.props.location.search;
    const next = nextProps.location.search;
    if (!next || !current || !isEqual(current, next)) {
      this.handleMount(next);
    }
  }

  handleMount = (searchString) => {
    if (this.state.searching) return;

    if (searchString && searchString.length) {
      const { page, ...rest } = searchStringToQueryObject(searchString);

      const searchPage = page ? parseInt(page, 10) - 1 : 0;
      this.handleSearch(searchPage, rest, "from mount");
    }
  }

  handleReset = () => {
    this.setState(DEFAULT_STATE);
    this.form.reset();
  }

  handleSubmit = (query) => {
    replaceQuery(query);
    this.setState(clone(DEFAULT_STATE), () => {
      this.handleSearch(0, query, "from submit");
    });
  }

  handlePageQuery = (page, cb) => {
    if (page > 0) {
      addQuery({ page: page + 1 });
    } else {
      removeQuery("page");
    }

    if (cb) cb();
  }

  handleSearch = (page = 0, q, source) => {
    if (this.state.searching) return;

    const query = q || this.state.query;
    const params = { query, page };

    const stringified = JSON.stringify(params);
    const cachedResults = this.cache[stringified];

    if (cachedResults) {
      return this.setState({
        searching: true,
        query,
        activePage: page,
        results: cachedResults.stories,
        totalResults: cachedResults.total
      }, () => this.handlePageQuery(page, () => {
        this.setState({ searching: false });
      }));
    }

    this.setState({
      searching: true,
      query,
      activePage: page,
      fetching: q === undefined
    }, () => this.handlePageQuery(page));

    searchStories(params, (results) => {
      if (!results || results === "error") {
        return this.setState({
          searching: false,
          fetching: false,
          error: true
        })
      }

      this.cache[stringified] = results;

      this.setState({
        results: results.stories,
        totalResults: results.total,
        searching: false,
        fetching: false
      });
    });
  }

  render() {
    const {
      query,
      error,
      results,
      searching,
      fetching,
      activePage,
      totalResults
    } = this.state;

    return (
      <Wrapper onHeaderClick={this.handleReset}>
        <Media query="(max-width: 600px)">
          {mobile => (
            <Search
              ref={r => this.form = r}
              onSubmit={this.handleSubmit}
              searching={searching}
              fetching={fetching}
              mobile={mobile}
            />
          )}
        </Media>
        {(results || error) && (
          <Results
            text={query.text || query._all}
            error={error}
            results={results}
            searching={searching}
            fetching={fetching}
            totalResults={totalResults}
            handleSearch={this.handleSearch}
            activePage={activePage}
          />
        )}
      </Wrapper>
    );
  }
}
