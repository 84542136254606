import React, {Component} from "react";
import clone from "lodash/clone";
import cx from "classnames";
import "./style.css";

export default class CheckboxGroup extends Component {
  static defaultProps = {
    onChange: () => {}
  }

  handleAllChange = (event) => {
    let selected;
    if (this.props.selected.length === this.props.options.length) {
      selected = [];
    } else {
      selected = this.props.options;
    }
    this.props.onChange(selected);
  }

  handleItemChange = (value) => (event) => {
    let selected = clone(this.props.selected);
    const valueIndex = selected.indexOf(value);
    if (valueIndex < 0) {
      selected.push(value);
    } else {
      selected.splice(valueIndex, 1);
    }

    this.props.onChange(selected);
  }

  renderItem = (value) => {
    return (
      <div key={value} className="checkboxItem">
        <input
          type="checkbox"
          name={value}
          checked={this.props.selected.indexOf(value) > -1}
          onChange={this.handleItemChange(value)}
        />
        <label name={value} onClick={this.handleItemChange(value)}>
          {value}
        </label>
      </div>
    );
  }

  render() {
    const { label, title, options, selected, inline, all } = this.props;
    return (
      <div
        key={label || title}
        className={cx("group", {"inline": inline})}
      >
        {!!label && <strong className="checkboxTitle">{label}</strong>}
        {(!!title || all) &&
          <div className="firstItem checkboxItem">
            <input
              type="checkbox"
              onChange={this.handleAllChange}
              checked={selected.length === options.length}
            />
            <label onClick={this.handleAllChange}>
              {title ? `ALL ${title}` : "ALL"}
            </label>
          </div>
        }
        {options.map(this.renderItem)}
      </div>
    )
  }
}
