import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Button from "../Button";
import classnames from "classnames";
import "./style.css";

export default class Dropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    onToggle: PropTypes.func,
    label: PropTypes.string,
    open: PropTypes.bool,
    children: PropTypes.any
  }

  render() {
    return (
      <div className="dropdownWrap">
        <Button
          onClick={this.props.onToggle}
          className={`${this.props.label}ButtonToggle`}
        >
          {this.props.label} ▾
        </Button>
        <div className={classnames("dropdown", this.props.className, { "open": this.props.open })}>
          <div className="buttonWrap">
            <Button
              onClick={this.props.onToggle}
              className={`${this.props.label}ButtonToggle`}
            >
              close
            </Button>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}
