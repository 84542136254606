import { productionUrl, devUrl } from "./constants";

function apiRequest(
  endpoint,
  optionOverrides = {}
) {
  // const apiUrl = process.env.NODE_ENV === "development" ? devUrl : productionUrl;
  const url = `${productionUrl}/${endpoint}`;
  const options = optionOverrides;

  options.headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return window.fetch(url, options);
}

export async function searchStories(query, cb) {
  let response;
  try {
    response = await apiRequest("stories/search", { method: "POST", body: query });
  } catch(err) {
    return cb("error")
  }

  if (response.status !== 200) {
    return cb("error");
  }

  response.json().then(json => {
    cb(json);
  }).catch(err => cb("error"));
};

export async function sendMessage(body, cb) {
  let response;
  try {
    response = await apiRequest("messages", { method: "POST", body });
  } catch(err) {
    return cb("error");
  }
  const message = response.status === 200 ? "success" : "error";
  cb(message);
}
